<template>
  <div class="header">
    <div class="collapse-btn" @click="collapseChage">
      <el-icon v-if="!collapse">
        <fold />
      </el-icon>
      <el-icon v-else>
        <expand />
      </el-icon>
    </div>
    <div class="logo">JE Education Management</div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- user picture -->
        <div class="user-avator">
          <img src="../../assets/img/img.jpg" />
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <el-icon>
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item divided command="loginout">
                Sign Out</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  <el-dialog
    title="Session expired warning!"
    v-model="showSessionExpiredAlert"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="420px"
  >
    <el-row>
      Your session is already expired! before you quit out this page, you can
      click Login button to get the latest session and then continue on your
      work.
    </el-row>
    <el-row class="buttonLayer">
      <el-button type="warning" @click="gotoLogin">Login</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import { deleteData } from "../../service/api/index";

export default {
  data() {
    return {
      name: "header",
      controllerUrl: "/login",
      showSessionExpiredAlert: false,
    };
  },
  created() {
    this.$emitter.on("SESSION_EXPIRED_NOTIFICATION", (arg) => {
      console.log("received event:", "SESSION_EXPIRED_NOTIFICATION", arg);
    });
  },
  mounted() {
    if (document.body.clientWidth < 1024) {
      this.collapseChange();
    }
    this.regularCheckLoginStatus();
  },
  computed: {
    username() {
      let username = this.$user.getUsername();
      return username ? username : this.name;
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
  methods: {
    handleCommand(command) {
      if (command == "loginout") {
        deleteData(this.controllerUrl).then((res) => {
          console.log("sign out", res);
          if (res.result && res.code === "200") {
            this.$message.success("Sign out successfully");
            console.log("current user", this.$user.currentUser());
            this.$user.signOut();
            this.$router.push("/login");
          } else {
            this.$message.error(
              "Sign out failed, error message: " + res.message
            );
          }
        });
      }
    },
    collapseChange() {
      this.$store.commit("handleCollapse", !this.collapse);
    },
    regularCheckLoginStatus() {
      var _this = this;
      setInterval(() => {
        if (!_this.$user.isValid()) {
          this.showSessionExpiredAlert = true;
        } else {
          this.showSessionExpiredAlert = false;
        }
      }, 1000);
    },
    gotoLogin() {
      let route = this.$router.resolve({ path: "/login" });
      this.$appSetting.loginWindow = window.open(route.href, "_blank");
    },
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 400px;
  line-height: 70px;
  font-size: 28px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}
.el-dropdown-menu__item {
  text-align: center;
}
.buttonLayer {
  margin-top: 20px;
}
.buttonLayer button {
  margin-left: auto;
}
</style>
