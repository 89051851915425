import axios from "axios";
import UserSession from "../session/userSession";
import AppSetting from "../utils/appSetting";
import { showLoading, hideLoading } from "../utils/loading";

const service = axios.create({
  baseURL: AppSetting.getAPIRoot(),
  // baseURL: "https://jeeducation-api.azurewebsites.net/api/console",
  timeout: AppSetting.getConnectionTimeout(),
  isShowLoading: true,
});

service.interceptors.request.use(
  (config) => {
    if (config.isShowLoading) showLoading();

    if (!navigator.onLine) {
      AppSetting.vueInstance.config.globalProperties.$alert(
        "Your network is unavailable",
        "Warning",
        {
          confirmButtonText: "OK",
          type: "warning",
        }
      );
    }

    const exemptionPaths = AppSetting.getExemptionBackendPaths();
    if (exemptionPaths.indexOf(config.url) >= 0) {
      return config;
    }

    if (!UserSession.isValid()) {
      if (UserSession.tryExchangeAccessToken() === "OK") {
        return config;
      }

      AppSetting.vueInstance.config.globalProperties.$emitter.emit(
        "SESSION_EXPIRED_NOTIFICATION"
      );
      return null;
    }
    config.headers["Authorization"] = "Bearer " + UserSession.getAccessToken();
    return config;
  },
  (error) => {
    AppSetting.vueInstance.config.globalProperties.$message.error(
      error.message
    );
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.config.isShowLoading) hideLoading();
    if (response.status === 200) {
      if (
        response.config.responseType &&
        response.config.responseType === "blob"
      ) {
        return response;
      }

      return response.data;
    } else if (response.status === 401) {
      AppSetting.vueInstance.config.globalProperties.$emitter.emit(
        "SESSION_EXPIRED_NOTIFICATION"
      );
      return;
    } else {
      if (
        response.config.responseType &&
        response.config.responseType === "blob"
      ) {
        return response;
      }

      return response.data;
    }
  },
  (error) => {
    if (error.config.isShowLoading) hideLoading();
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      AppSetting.vueInstance.config.globalProperties.$emitter.emit(
        "SESSION_EXPIRED_NOTIFICATION"
      );
      return;
    }
    AppSetting.vueInstance.config.globalProperties.$message.error(
      error.message
    );
    return Promise.reject();
  }
);

export default service;
