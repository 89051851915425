import { createRouter, createWebHistory } from "vue-router";
import AppSetting from "../utils/appSetting";
import UserSession from "../session/userSession";
import adminRoutes from "../models/adminRoutes";
import studentRoutes from "../models/studentRoutes";

const signInPath = AppSetting.getSignIn();
const exemptionPageNames = AppSetting.getExemptionPageNames();
const routes = process.env.VUE_APP_ROLE === "admin" ? adminRoutes : studentRoutes;
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log("beforeEach", "to", to, "from", from, "next", next);
  document.title = `${to.meta.title} | JE Education Management`;
  let user = UserSession.currentUser();
  // console.log("router", user);
  if (!user.isValid()) {
    if (exemptionPageNames.indexOf(to.name) >= 0) {
      next();
    } else {
      next(signInPath);
    }
  } else {
    next();
  }
});
// router.afterEach((to, from, failure) => {
//   console.log("afterEach", "to", to, "from", from, "failure", failure);
// });

export default router;
