import { createApp } from "vue";
import App from "./App.vue";
import router from "./service/plugins/router";
import store from "./service/utils/store";
import mitt from "mitt";
import installElementPlus from "./service/plugins/elementPlus";
import UserSession from "./service/session/userSession";
import AppSetting from "./service/utils/appSetting";
import formatter from "./service/utils/formatter";
import tableCellFormatter from "./service/utils/tableCellFormatter";
import "./assets/css/icon.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueClipboard from "vue-clipboard2";

async function init() {
  const app = createApp(App);
  AppSetting.vueInstance = app;

  await AppSetting.init();

  installElementPlus(app);
  const emitter = mitt();

  app
    .use(store)
    .use(CKEditor)
    .use(router)
    .use(VueClipboard)
    .mount("#app");

  app.config.globalProperties.$formatter = formatter;
  app.config.globalProperties.$tableCellFormatter = tableCellFormatter;
  app.config.globalProperties.$user = UserSession;
  app.config.globalProperties.$emitter = emitter;
  app.config.globalProperties.$appSetting = AppSetting;
}

String.prototype.rtrim = function(s) {
  return this.replace(new RegExp(s + "*$"), "");
};

init();
