<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
