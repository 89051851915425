import Home from "../../views/admin/Home.vue";

const adminRoutes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "Home",
        },
        component: () => import("../../views/admin/Dashboard.vue"),
      },
      {
        path: "/studentList",
        name: "studentList",
        meta: {
          title: "Student List",
        },
        component: () => import("../../views/admin/StudentList.vue"),
      },
      {
        path: "/userList",
        name: "userList",
        meta: {
          title: "Users",
        },
        component: () => import("../../views/admin/UserList.vue"),
      },
      {
        path: "/courseList",
        name: "courseList",
        meta: {
          title: "Courses",
        },
        component: () => import("../../views/admin/CourseList.vue"),
      },
      {
        path: "/lessonList",
        name: "lessonList",
        meta: {
          title: "Lessons",
        },
        component: () => import("../../views/admin/LessonList.vue"),
      },
      {
        path: "/classRoomList",
        name: "classRoomList",
        meta: {
          title: "ClassRooms",
        },
        component: () => import("../../views/admin/ClassRoomList.vue"),
      },
      {
        path: "/testReportList",
        name: "testReportList",
        meta: {
          title: "Test Reports",
        },
        component: () => import("../../views/admin/TestReportList.vue"),
      },
      {
        path: "/receiptList",
        name: "receiptList",
        meta: {
          title: "Receipts",
        },
        component: () => import("../../views/admin/ReceiptList.vue"),
      },
      {
        path: "/userLogs",
        name: "userLogs",
        meta: {
          title: "User Logs",
        },
        component: () => import("../../views/admin/UserLogs.vue"),
      },
      {
        path: "/buildReceiptTemplate",
        name: "buildReceiptTemplate",
        meta: {
          title: "Build Receipt Template",
        },
        component: () => import("../../views/admin/BuildReceiptTemplate.vue"),
      },
      {
        path: "/emailTemplateList",
        name: "emailTemplateList",
        meta: {
          title: "Email Template",
        },
        component: () => import("../../views/admin/EmailTemplateList.vue"),
      },
      {
        path: "/sendingEmail",
        name: "sendingEmail",
        meta: {
          title: "Sending Email",
        },
        component: () => import("../../views/admin/SendingEmail.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        meta: {
          title: "Global setting",
        },
        component: () => import("../../views/admin/Settings.vue"),
      },
      {
        path: "/receiptDetail",
        name: "receiptDetail",
        meta: {
          title: "Receipt detail",
        },
        component: () => import("../../views/common/ReceiptTemplate.vue"),
      },
      {
        path: "/pointsRecordList",
        name: "pointsRecordList",
        meta: {
          title: "Points Issued List",
        },
        component: () => import("../../views/admin/PointsRecordList.vue"),
      },
      {
        path: "/404",
        name: "404",
        meta: {
          title: "Not Found",
        },
        component: () => import("../../views/common/404.vue"),
      },
      {
        path: "/403",
        name: "403",
        meta: {
          title: "Unauthenticated",
        },
        component: () => import("../../views/common/403.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Sign In",
    },
    component: () => import("../../views/admin/Login.vue"),
  },
];

export default adminRoutes;
