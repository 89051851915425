import { Base64 } from "js-base64";
import AppSetting from "../utils/appSetting";
import axios from "axios";

class UserSession {
  _appId = null;
  _username = null;
  _accessToken = null;
  _refreshToken = null;
  _signInDateTime = null;
  _expiredMinutes = null;
  _expiredMinutesForRefreshToken = null;
  _sessionName = `${AppSetting.getRole()}_je_user`;

  constructor(
    appId,
    username,
    accessToken,
    refreshToken,
    expiredMinutes,
    expiredMinutesForRefreshToken,
    signInDateTime
  ) {
    this._appId = appId;
    this._username = username;
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    this._expiredMinutes = expiredMinutes;
    this._expiredMinutesForRefreshToken = expiredMinutesForRefreshToken;
    this._signInDateTime = signInDateTime;
  }

  currentUser() {
    let b64 = localStorage.getItem(this._sessionName);
    if (b64) {
      try {
        let json = Base64.decode(b64);
        let obj = JSON.parse(json);
        // let obj = JSON.parse(b64);
        return new UserSession(
          obj._appId,
          obj._username,
          obj._accessToken,
          obj._refreshToken,
          obj._expiredMinutes,
          obj._expiredMinutesForRefreshToken,
          obj._signInDateTime
        );
      } catch {
        localStorage.removeItem(this._sessionName);
      }
    }
    return new UserSession("", "", 0, 0);
  }

  getAppId() {
    return this.currentUser()._appId || "";
  }

  getAccessToken() {
    return this.currentUser()._accessToken || "";
  }

  getRefreshToken() {
    return this.currentUser()._refreshToken || "";
  }

  getUsername() {
    return this.currentUser()._username || "";
  }

  signIn(
    appId,
    username,
    accessToken,
    refreshToken,
    expiredMinutes,
    expiredMinutesForRefreshToken
  ) {
    try {
      let b64 = Base64.encode(
        JSON.stringify(
          new UserSession(
            appId,
            username,
            accessToken,
            refreshToken,
            expiredMinutes,
            expiredMinutesForRefreshToken,
            new Date().getTime()
          )
        )
      );

      localStorage.removeItem(this._sessionName);
      localStorage.setItem(this._sessionName, b64);
    } catch {
      localStorage.removeItem(this._sessionName);
    }
  }

  signOut() {
    localStorage.removeItem(this._sessionName);
    this._appId = "";
    this._username = "";
    this._accessToken = "";
    this._refreshToken = "";
    this._expiredMinutes = 0;
    this._expiredMinutesForRefreshToken = 0;
    this._signInDateTime = 0;
  }

  async tryExchangeAccessToken() {
    try {
      let user = this.currentUser();
      if (
        !(
          user._appId &&
          user._username &&
          user._accessToken &&
          user._accessToken.length == 22 &&
          user._refreshToken &&
          user._refreshToken.length == 22
        )
      ) {
        return "EXPIRY";
      }

      let response = await axios.post(
        "login/exchangeAccessToken",
        {
          appId: user._appId,
          accessToken: user._accessToken,
          refreshToken: user._refreshToken,
        },
        {
          baseURL: AppSetting.getAPIRoot(),
          timeout: AppSetting.getConnectionTimeout(),
        }
      );

      let res = response.data;
      if (res.result && res.code === "200") {
        this.signIn(
          user._appId,
          user._username,
          res.result.accessToken,
          res.result.refreshToken,
          res.result.accessTokenExpiredInMinutes,
          res.result.refreshTokenExpiredInMinutes
        );
        return "OK";
      }
    } catch {
      // ignore
    }
    return "EXPIRY";
  }

  isFreshTokenValid() {
    let user = this.currentUser();
    console.log("isFreshTokenValid", user);
    return (
      user._username &&
      user._refreshToken &&
      user._refreshToken.length == 22 &&
      user._signInDateTime + user._expiredMinutesForRefreshToken * 60000 >
        new Date().getTime()
    );
  }

  isValid() {
    let user = this.currentUser();
    return (
      user._appId &&
      user._username &&
      user._accessToken &&
      user._accessToken.length == 22 &&
      user._refreshToken &&
      user._refreshToken.length == 22 &&
      user._signInDateTime + user._expiredMinutes * 60000 > new Date().getTime()
    );
  }
}

export default new UserSession();
