var formatter = {
  formatDate: function(strValue, format) {
    if (strValue == null || strValue == "") return "";
    let y = "dd/MM/yyyy";
    if (format) y = format;
    let x = new Date(strValue);
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function(v) {
      return x
        .getFullYear()
        .toString()
        .slice(-v.length);
    });
  },
  formatDecimal: function(val, decimalPlaces) {
    decimalPlaces = decimalPlaces || 2;
    val = val || 0;
    let numVal = 0;
    if (typeof val != "number") {
      numVal = parseFloat(val);
    } else {
      numVal = val;
    }
    return numVal.toFixed(decimalPlaces);
  },
};
export default formatter;
