import request from "../plugins/request";

export const getData = (path, query, config) => {
  let cfg = {
    url: path,
    method: "GET",
    params: query,
  };
  for (let p in config) {
    cfg[p] = config[p];
  }

  return request(cfg);
};

export const postData = (url, data, config) => {
  let cfg = {
    url: url,
    method: "POST",
    data: data,
  };
  for (let p in config) {
    cfg[p] = config[p];
  }

  return request(cfg);
};

export const putData = (path, resourceId, data, config) => {
  let cfg = {
    url: path.rtrim("/") + "/" + resourceId,
    method: "PUT",
    data: data,
  };
  for (let p in config) {
    cfg[p] = config[p];
  }

  return request(cfg);
};

export const deleteData = (path, resourceId, query, config) => {
  var finalUrl = path;
  if (resourceId) {
    finalUrl = `${path.rtrim("/")}/${resourceId}`;
  }

  let cfg = {
    url: finalUrl,
    method: "DELETE",
    params: query,
  };
  for (let p in config) {
    cfg[p] = config[p];
  }

  return request(cfg);
};
export const downloadFile = (method, url, data, config) => {
  let cfg = {
    url: url,
    method: method,
    params: method === "GET" || method === "DELETE" ? data : null,
    data: method === "POST" || method === "PUT" ? data : null,
    responseType: "blob",
  };
  for (let p in config) {
    cfg[p] = config[p];
  }

  return request(cfg).then((response) => {
    let content = response.data;
    let blob = new Blob([content], { type: response.headers["content-type"] });
    let contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("=")[1];
    fileName = fileName.replace(/"/gi, "");
    let objectUrl = URL.createObjectURL(blob);
    if (response.config.enablePreview) {
      let w = window.open(objectUrl, "_blank");
      w && w.focus();
    } else {
      if ("download" in document.createElement("a")) {
        // non-IE download
        let elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = objectUrl;
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        // IE10+ download
        navigator.msSaveBlob(blob, fileName);
      }
    }
  });
};
