import formatter from "./formatter";

var tableCellFormatter = {
  formatDate: function(row, column, cellValue) {
    if (cellValue == null || cellValue == "") return "";
    return formatter.formatDate(cellValue);
  },
  formatDecimal: function(row, column, cellValue) {
    return formatter.formatDecimal(cellValue);
  },
};
export default tableCellFormatter;
